import React from "react";

export default function MattersIcon(props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 16C0 7.16344 7.16344 0 16 0H48C56.8366 0 64 7.16344 64 16V48C64 56.8366 56.8366 64 48 64H16C7.16344 64 0 56.8366 0 48V16Z" fill="#F2FAF7" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M39.6874 44.5867C42.1753 44.5869 44.6074 43.8494 46.6762 42.4675C48.745 41.0855 50.3576 39.1212 51.3102 36.8229C52.2627 34.5245 52.5123 31.9953 52.0276 29.5551C51.5428 27.1149 50.3454 24.8732 48.5867 23.1134C46.828 21.3537 44.587 20.1549 42.147 19.6687C39.7071 19.1825 37.1778 19.4307 34.8788 20.3818C32.5799 21.333 30.6147 22.9444 29.2315 25.0124C27.8483 27.0804 27.1094 29.5121 27.1082 32C27.1072 33.6525 27.4318 35.2891 28.0635 36.8161C28.6953 38.3432 29.6217 39.7308 30.7899 40.8997C31.9581 42.0685 33.3451 42.9958 34.8718 43.6284C36.3984 44.261 38.0348 44.5867 39.6874 44.5867Z" fill="url(#paint0_linear)" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25.7067 45.9808C28.4701 45.9808 31.1714 45.1615 33.4691 43.6264C35.7669 42.0913 37.5579 39.9094 38.6157 37.3566C39.6735 34.8037 39.9507 31.9945 39.4121 29.2842C38.8736 26.5738 37.5435 24.084 35.59 22.1295C33.6365 20.175 31.1474 18.8435 28.4374 18.3035C25.7273 17.7635 22.918 18.0392 20.3645 19.0956C17.8111 20.1521 15.6283 21.9419 14.092 24.2389C12.5557 26.5358 11.7349 29.2367 11.7334 32C11.7324 33.8356 12.0931 35.6535 12.7949 37.3497C13.4967 39.0458 14.5258 40.5871 15.8235 41.8855C17.1211 43.1838 18.6618 44.2137 20.3576 44.9164C22.0535 45.6191 23.8711 45.9808 25.7067 45.9808Z" fill="url(#paint1_linear)" />
      <defs>
        <linearGradient id="paint0_linear" x1="30.8116" y1="25.0816" x2="49.5017" y2="39.6576" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D7EAE1" />
          <stop offset="1" stop-color="#79B1A6" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="18.3073" y1="24.3232" x2="34.4715" y2="41.0933" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F4E2BC" />
          <stop offset="1" stop-color="#BF9F5E" />
        </linearGradient>
      </defs>
    </svg>
  );
}
